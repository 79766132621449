@use "../colors" as colors;

@property --gradient-percent {
	syntax: "<percentage>";
	initial-value: 60%;
	inherits: false;
}

.Achievements {
	padding: 1.5rem 0rem;
	max-width: 1200px;

	.achievementContainer {
		padding: 2px;
		border-radius: 35px;
		background: radial-gradient(
			at 0% 0%,
			colors.$turquoise 20%,
			colors.$dark-gray var(--gradient-percent)
		);

		transition: --gradient-percent 0.3s;

		&:hover {
			--gradient-percent: 200%;
		}
	}

	.AchievementCard {
		padding: 1rem 1rem 0rem 1rem;
		border-radius: 35px;
		background-color: colors.$dark-gray;

		a {
			color: colors.$gray;
			&:hover {
				color: colors.$gray;
			}
		}
	}
}
