@use "../colors" as colors;

@property --gradient-percent {
	syntax: "<percentage>";
	initial-value: 50%;
	inherits: false;
}

.About {
	max-width: 900px;
	padding: 1.5rem 0rem;

	.profilePictureContainer {
		border-radius: 35px;
		background: radial-gradient(
			at 100% 100%,
			colors.$turquoise 10%,
			colors.$dark-gray var(--gradient-percent)
		);

		transition: --gradient-percent 0.2s;

		&:hover {
			--gradient-percent: 200%;
		}
	}

	.profilePicture {
		border: 10px solid colors.$dark-gray;
		border-radius: 35px;
		margin: 2px;
		padding: 1px;
		background-color: colors.$dark-gray;
	}
}
