@use "../colors" as colors;

.Skills {
	padding: 1.5rem 0rem;
	max-width: 900px;

	.skillContainer {
		padding: 0px 0px 0px 2px;
		margin: 0.5rem 0.75rem;
		height: fit-content;

		background: radial-gradient(
			at 0 50%,
			colors.$turquoise 20%,
			colors.$dark-gray 70%
		);
	}

	.skillTitle {
		h6 {
			margin-bottom: 0.5rem;
		}
	}

	.SkillCard {
		padding: 0rem 0rem 0.5rem 0.75rem;
		background-color: colors.$dark-gray;
	}
}
