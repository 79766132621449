@use "./colors" as colors;

body {
	margin: 0;
	font-family: "Montserrat";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: colors.$gray;
	background-color: colors.$dark-gray;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: colors.$turquoise;
	&:hover {
		text-decoration: none;
		color: darken(colors.$turquoise, 20);
	}
}

ul {
	padding-inline-start: 20px;
}

li {
	padding-bottom: 0.5rem;
}

.text-highlight {
	color: colors.$light-gray;
	font-family: "Montserrat-Medium";
}

.Icon {
	border-radius: 10px;
}

@font-face {
	font-family: Montserrat;
	src: url("../public/fonts/Montserrat-Regular.ttf");
}

@font-face {
	font-family: Monoton;
	src: url("../public/fonts/Monoton-Regular.ttf");
}

@font-face {
	font-family: Syncopate;
	src: url("../public/fonts/Syncopate-Regular.ttf");
}

@font-face {
	font-family: Montserrat-Medium;
	src: url("../public/fonts/Montserrat-Medium.ttf");
}
