@use "../colors" as colors;

.Experience {
	padding: 1.5rem 0rem;
	max-width: 1000px;

	.ToggleButton.ToggleButton {
		color: colors.$turquoise;
		border: 2px solid colors.$turquoise;
		outline: none;

		&.Mui-selected {
			background-color: colors.$turquoise;
			color: colors.$dark-gray;
			font-weight: bolder;
		}

		&:hover:not(.Mui-selected) {
			background-color: darken(colors.$turquoise, 50);
			border-color: colors.$turquoise;
		}

		&.first {
			border-radius: 15px 0px 0px 15px;
		}

		&.inner {
			border-left: 0px;
		}

		&.last {
			border-radius: 0px 15px 15px 0px;
		}
	}

	.JobCard {
		padding-top: 1rem;
		min-height: 250px;
	}

	.title {
		padding-right: 0.5rem;
	}
}
